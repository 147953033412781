import React, { Suspense, useEffect, useState} from "react";
import { connect } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import Spinner from "react-spinner-material";
import ScrollToTop from "../../components/Scroll/ScrollToTop";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import { closeDetailsDialog, downloadPDF } from "../../actions/details-dialog";
import {
  setGlobalRoles,
  setIsAdmin,
  setRole,
  setRoles,
  setUserName,
  signInUser,
  signOutUser,
  updateAuthDone,
  setApplicationType,
} from "../../actions/auth";
import {
  fetchSuccess,
  initProjectsState,
  setErrorWithMessage,
} from "../../actions/projects";
import { setConfiguration } from "../../actions/server-configuration.action";
import history from "../../history";
import auth from "../../api/auth";
import { RolesDialog } from "../../components/RolesDialog/rolesDialog";
import { LogoutPopup } from "../../components/LogoutPopup/LogoutPopup";
import { showLogoutPopup } from "../../actions/logoutPopup.actions";
import { ConfirmPopup } from "../../components/ConfirmPopup/ConfirmPopup";
import { ConfirmationServiceProvider } from "../../components/ConfirmationContext/ConfirmationContext";
import { CommentPopupServiceProvider } from "../../components/Comment/CommentPopup/CommentPopupContext";
import { appRole, navigationRoles } from "../../utils/roles.utils";
import { FlashMessage } from "../../components/FlashMessage/FlashMessage";
import massp from "../../api/massp";
import { checkUserAcknowledgments } from "../../utils/user-check-utils";

const DetailsDialog = React.lazy(() =>
  import("../../components/DetailsDialog/DetailsDialog")
);

const Info = React.lazy(() => import("../../components/Info/Info"));
const Header = React.lazy(() =>
  import("../../components/Header/App/AppHeader")
);
const UserAcknowledgements = React.lazy(() =>
  import("../user-acknowledgements/user-acknowledgements")
);
const ProjectsOverview = React.lazy(() =>
  import("../ProjectsOverview/ProjectsOverview")
);
const ApprovalOverview = React.lazy(() =>
  import("../ApprovalOverview/ApprovalOverview")
);

const BuildsOverview = React.lazy(() =>
  import("../BuildsOverview/BuildsOverview")
);
const ProjectDetails = React.lazy(() =>
  import("../project-details/project-details")
);
const GenericFindingsForm = React.lazy(() =>
  import("../GenericFingingsForm/GenericFindingsForm")
);

const SecurityComponent = React.lazy(() =>
  import("../build-steps/security/security-component")
);

const PrepareComponent = React.lazy(() =>
  import("../build-steps/prepare/prepare-component")
);

const DataProtectionComponent = React.lazy(() =>
  import("../build-steps/data-protection/data-protection-component")
);

const GeneralDeliverablesComponent = React.lazy(() =>
  import("../build-steps/general-deliverables/general-deliverables-component")
);

const AppAccountGovernanceComponent = React.lazy(() =>
  import("../build-steps/app-account-governance/app-account-governance-component")
);

const LegalComponent = React.lazy(() =>
  import("../build-steps/legal/legal-component")
);

const FossComponent = React.lazy(() =>
  import("../build-steps/foss/foss-component")
);
const BrandDesign = React.lazy(() =>
  import("../build-steps/brand-design/brand-design-component")
);
const BuildApproval = React.lazy(() =>
  import("../BuildApproval/BuildApproval")
);
const AdminUserPage = React.lazy(() =>
  import("../admin-user-page/admin-user-page")
);
const UsermaintenanceGlobalUserPage = React.lazy(() =>
  import("../usermaintenance-globaluser-page/usermaintenance-globaluser-page")
);
const UsermaintenanceProjectUserPage = React.lazy(() =>
  import("../usermaintenance-projectuser-page/usermaintenance-projectuser-page")
);
const S3StorageMonitoringPage = React.lazy(() =>
  import("../s3storagemonintoring-page/s3storagemonintoring-page")
);
const ScanProfilePage = React.lazy(() =>
  import("../scanprofile-page/scanprofile-page")
);
const AppConfigurationComponent = React.lazy(() =>
  import("../app-configuration/app-configuration")
);
const LinksConfigurationComponent = React.lazy(() =>
  import("../links-configuration/links-configuration")
);
const NotAuthorized = React.lazy(() =>
  import("../NotAuthorized/NotAuthorized")
);
const NotFound = React.lazy(() => import("../NotFound/NotFound"));
const DocumentStorage = React.lazy(() =>
  import("../document-storage/document-storage")
);
const AagDocumentStorage = React.lazy(() =>
import("../build-steps/app-account-governance/aag-document-storage")
);
const HomePage =  React.lazy(()=> import("../homePage/home-page"));
function App({
  authInfo,
  detailsDialog,
  onCloseDetailsDialog,
  onDownloadPdf,
  onLogin,
  fetchSuccess,
  setRole,
  setRoles,
  setGlobalRoles,
  role,
  setUserName,
  setErrorWithMessage,
  showLogoutPopup,
  isAdmin,
  setIsAdmin,
  onLogout,
  initProjectsState,
  setServerConfiguration,
  authUpdateDone,
  setApplicationType,
}) {
  // const [isScanProfileEnabled,setisScanProfileEnabled] = useState(false)
  useEffect(() => {
    massp.getConfiguration().then((configuration) => {
      // setisScanProfileEnabled(configuration.isScanProfileEnabled)
      setServerConfiguration(configuration);
      fetchAuthInfo();
    });
  }, []);

  useEffect(() => {
    if (authInfo.update) {
      fetchAuthInfo();
    }
  }, [authInfo.update]);

  function fetchAuthInfo() {
    let currentAuth = { ...authInfo };
    auth
      .getUserInfo()
      .then((userObj) => {
        setIsAdmin(userObj.roles.includes(appRole.ADMIN));
        setGlobalRoles(userObj.roles);
        setUserName(userObj.firstName);
        setApplicationType(userObj.applicationType)

        if (userObj.navigationRoles?.length > 0) {
          let displayRoles = userObj.navigationRoles.filter((role) =>
            navigationRoles.includes(role)
          );
          setRoles(displayRoles);

          if (displayRoles.length === 0) {
            if (userObj.navigationRoles.includes(appRole.PROJECT_CREATOR)) {
              onLogin(userObj.firstName, appRole.PROJECT_CREATOR);
            } else {
              onLogin(userObj.firstName, userObj.navigationRoles[0]);
            }
          } else if (displayRoles.length === 1) {
            onLogin(userObj.firstName, displayRoles[0]);
          } else {
            if (
              !role ||
              Array.isArray(role) ||
              !userObj.navigationRoles.includes(currentAuth.role)
            ) {
              setRole(displayRoles);
            }
          }
          checkUserAcknowledgments(userObj.navRole);
        } else {
          setRole(null);
          setRoles([]);
          setErrorWithMessage("Your account is not permitted");
        }

        authUpdateDone();
        fetchSuccess();
      })
      .catch(() => {
        fetchSuccess();
      });
  }

  return (
    <CommentPopupServiceProvider>
      <ConfirmationServiceProvider>
        <Router history={history}>
          <ScrollToTop>
            <Suspense fallback={<Spinner size={120} visible={false} />}>
              <Header />
              <Switch>
                {/* Landing Page */}
                <Redirect exact from="/" to={'/projectsOverview'} />
                <PrivateRoute
                  exact
                  path = "/home"
                  component={HomePage}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/projectsOverview"
                  component={ProjectsOverview}
                  approvalComponent={ApprovalOverview}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/user/acknowledgements"
                  component={UserAcknowledgements}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/projects"
                  component={ProjectsOverview}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/approvals"
                  approvalComponent={ApprovalOverview}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/builds/:projectId"
                  component={BuildsOverview}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/projects/:id"
                  component={ProjectDetails}
                  approvalComponent={ProjectDetails}
                />
                <PrivateRoute
                  exact
                  path="/projects/:id?tab=:tabId"
                  component={ProjectDetails}
                  approvalComponent={ProjectDetails}
                />
                <PrivateRoute
                  exact
                  path="/projects/:id/documents"
                  component={DocumentStorage}
                  approvalComponent={DocumentStorage}
                />
                <PrivateRoute
                  exact
                  path="/aag/upload"
                  component={AagDocumentStorage}
                  approvalComponent={AagDocumentStorage}
                />
                <PrivateRoute exact path="/add" component={ProjectDetails} />
                <PrivateRoute
                  exact
                  path="/prepare/:projectId/:buildStepId"
                  component={PrepareComponent}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/legal/:projectId/:buildStepId"
                  component={LegalComponent}
                  approvalComponent={LegalComponent}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/cdp/:projectId/:buildStepId"
                  component={DataProtectionComponent}
                  approvalComponent={DataProtectionComponent}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/bd/:projectId/:buildStepId"
                  component={BrandDesign}
                  approvalComponent={BrandDesign}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/gd/:projectId/:buildStepId"
                  component={GeneralDeliverablesComponent}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/aag/:projectId/:buildStepId"
                  component={AppAccountGovernanceComponent}
                  approvalComponent={AppAccountGovernanceComponent}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/ldsso/:projectId/:buildStepId"
                  component={GenericFindingsForm}
                  approvalComponent={GenericFindingsForm}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/sec/:projectId/:buildStepId"
                  component={SecurityComponent}
                  approvalComponent={DataProtectionComponent}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/foss/:projectId/:buildStepId"
                  component={FossComponent}
                  approvalComponent={DataProtectionComponent}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/ldsso/:projectId/:buildStepId"
                  component={GenericFindingsForm}
                  approvalComponent={DataProtectionComponent}
                  fluid
                />
                <PrivateRoute
                  exact
                  path="/build/:projectId/:buildId"
                  component={BuildApproval}
                  approvalComponent={BuildApproval}
                  fluid
                />
                <AdminUserPage
                  exact
                  path="/admin/user"
                  isAdmin={isAdmin}
                  component={AdminUserPage}
                  fluid
                />
                <UsermaintenanceGlobalUserPage
                  exact
                  path="/admin/usermaintenanceglobaluser"
                  isAdmin={isAdmin}
                  component={UsermaintenanceGlobalUserPage}
                  fluid
                />
                <UsermaintenanceProjectUserPage
                  exact
                  path="/admin/usermaintenanceprojectuser"
                  isAdmin={isAdmin}
                  component={UsermaintenanceProjectUserPage}
                  fluid
                />
                <AppConfigurationComponent
                  exact
                  path="/admin/appconfiguration"
                  isAdmin={isAdmin}
                  component={AppConfigurationComponent}
                  fluid
                />
                <LinksConfigurationComponent
                  exact
                  path="/admin/linksconfiguration"
                  isAdmin={isAdmin}
                  component={LinksConfigurationComponent}
                  fluid
                />
                <S3StorageMonitoringPage
                  exact
                  path="/admin/s3storagemonitoring"
                  isAdmin={isAdmin}
                  component={S3StorageMonitoringPage}
                  fluid
                />
                <ScanProfilePage
                  exact
                  path="/admin/scanprofile"
                  isAdmin={isAdmin}
                  component={ScanProfilePage}
                  fluid
                />
                <Route exact path="/info" component={Info} fluid />
                <Route
                  exact
                  path="/notAuthorized"
                  component={NotAuthorized}
                  fluid
                />
                <Route path="/notFound" component={NotFound} />
                <Redirect to="/notFound" />
              </Switch>
            </Suspense>
            {Array.isArray(role) && (
              <RolesDialog isOpen={Array.isArray(role)} />
            )}
            {showLogoutPopup && <LogoutPopup isOpen={showLogoutPopup} />}
            <ConfirmPopup />
            <Suspense fallback={<Spinner size={120} visible={false} />}>
              <DetailsDialog
                buildStep={detailsDialog}
                onClosed={onCloseDetailsDialog}
                onDownload={onDownloadPdf}
              />
            </Suspense>
          </ScrollToTop>
        </Router>
        <FlashMessage />
      </ConfirmationServiceProvider>
    </CommentPopupServiceProvider>
  );
}

const mapStateToProps = (state) => ({
  authInfo: state.auth,
  isFetching: state.projects.isFetching,
  isChecking: state.projects.isChecking,
  hasError: state.projects.hasError,
  pickedRole: state.projects.pickedRole,
  error: state.projects.error,
  detailsDialog: state.detailsDialog,
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  applicationType : state.auth.applicationType,
  globalRoles: state.auth.globalRoles,
  isAdmin: state.auth.isAdmin,
  showLogoutPopup: state.logout.showLogoutPopup,
  serverConfiguration:state.serverConfiguration
});

const mapDispatchToProps = (dispatch) => ({
  onCloseDetailsDialog: () => dispatch(closeDetailsDialog()),
  onDownloadPdf: (path) => dispatch(downloadPDF(path)),
  onLogout: () => dispatch(signOutUser()),
  initProjectsState: () => dispatch(initProjectsState()),
  onLogin: (userName, role) => dispatch(signInUser(userName, role)),
  fetchSuccess: () => dispatch(fetchSuccess()),
  setRole: (role) => {
    dispatch(setRole(role));
  },
  setRoles: (roles) => {
    dispatch(setRoles(roles));
  },
  setGlobalRoles: (roles) => {
    dispatch(setGlobalRoles(roles));
  },
  setUserName: (userName) => dispatch(setUserName(userName)),
  setApplicationType :(applicationType) => dispatch(setApplicationType(applicationType)),
  setErrorWithMessage: (message) => {
    dispatch(setErrorWithMessage(message));
  },
  triggerShowLogoutPopup: () => dispatch(showLogoutPopup()),
  setIsAdmin: (isAdmin) => {
    dispatch(setIsAdmin(isAdmin));
  },
  authUpdateDone: () => dispatch(updateAuthDone()),
  setServerConfiguration: (configuration) => {
    dispatch(setConfiguration(configuration));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
