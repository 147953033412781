import CIBuildStepApi from "../api/CIBuildStep.operation";
import { signOutUser } from "./auth";

const handleError = (dispatch, action) => (status) => {
  if (status === 403) {
    dispatch(signOutUser());
  }

  const error = status ? `Request Failed: ${status}` : "Request Failed";
  dispatch(action(error));
};

export const BuildActionTypes = {
  FETCH_APP_INFO_REQUEST: "FETCH_APP_INFO_REQUEST",
  FETCH_APP_INFO_FAILURE: "FETCH_APP_INFO_FAILURE",
  FETCH_APP_INFO_SUCCESS: "FETCH_APP_INFO_SUCCESS",
  UPLOAD_FILE_REQUEST: "UPLOAD_FILE_REQUEST",
  UPLOAD_FILE_FAILURE: "UPLOAD_FILE_FAILURE",
  UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
  DOWNLOAD_FILE_REQUEST: "DOWNLOAD_FILE_REQUEST",
  DOWNLOAD_FILE_FAILURE: "DOWNLOAD_FILE_FAILURE",
  DOWNLOAD_FILE_SUCCESS: "DOWNLOAD_FILE_SUCCESS",
  GET_APPROVAL_REQUEST: "GET_APPROVAL_REQUEST",
  GET_APPROVAL_FAILURE: "GET_APPROVAL_FAILURE",
  GET_APPROVAL_SUCCESS: "GET_APPROVAL_SUCCESS",
};

const fetchAppInfoRequest = () => ({
  type: BuildActionTypes.FETCH_APP_INFO_REQUEST,
});

const fetchAppInfoSuccess = (appInfo) => ({
  type: BuildActionTypes.FETCH_APP_INFO_SUCCESS,
  appInfo,
});

const fetchAppInfoFailure = (error) => ({
  type: BuildActionTypes.FETCH_APP_INFO_FAILURE,
  error,
});

const uploadFileRequest = () => ({
  type: BuildActionTypes.UPLOAD_FILE_REQUEST,
});

export const uploadFileSuccess = (fileInfo) => ({
  type: BuildActionTypes.UPLOAD_FILE_SUCCESS,
  fileInfo,
});

const uploadFileFailure = (error) => ({
  type: BuildActionTypes.UPLOAD_FILE_FAILURE,
  error,
});

const downloadFileRequest = () => ({
  type: BuildActionTypes.DOWNLOAD_FILE_REQUEST,
});

const downloadFileSuccess = () => ({
  type: BuildActionTypes.DOWNLOAD_FILE_SUCCESS,
});

const downloadFileFailure = (error) => ({
  type: BuildActionTypes.DOWNLOAD_FILE_FAILURE,
  error,
});

const getApprovalRequest = () => ({
  type: BuildActionTypes.GET_APPROVAL_REQUEST,
});

const getApprovalSuccess = (approvalDTO) => ({
  type: BuildActionTypes.GET_APPROVAL_SUCCESS,
  approvalDTO,
});

const getApprovalFailure = (error) => ({
  type: BuildActionTypes.GET_APPROVAL_FAILURE,
  error,
});

export const getAppInfo = (projectId) => (dispatch) => {
  dispatch(fetchAppInfoRequest());
  CIBuildStepApi.getInfo(projectId).then((appInfo) => {
    dispatch(fetchAppInfoSuccess(appInfo));
  }, handleError(dispatch, fetchAppInfoFailure));
};

export const getApproval = (buildStepId) => (dispatch) => {
  dispatch(getApprovalRequest);
  CIBuildStepApi.getApproval(buildStepId).then((approvalDTO) => {
    dispatch(getApprovalSuccess(approvalDTO));
  }, handleError(dispatch, getApprovalFailure));
};

export const downloadFile = (fileUUID, CIBuildStepId) => (dispatch) => {
  dispatch(downloadFileRequest());
  CIBuildStepApi.downloadAttachment(CIBuildStepId, fileUUID).then(() => {
    dispatch(downloadFileSuccess());
  }, handleError(dispatch, downloadFileFailure));
};
