import { alpha, createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { DarkThemePalette, LightThemePalette } from './ThemePalette';
import { AdvertismentTypographyOptions, ThemeTypographyOptions } from './ThemeTypography';
const BaseThemeOptions = {
    shape: {
        borderRadius: 2,
    },
    components: {
        MuiListItem: {
            styleOverrides: {
                // Adjust highlighting of list item. Remove if problems are caused
                root: ({ theme }) => ({
                    ['&.Mui-selected']: {
                        borderLeft: `4px solid ${theme.palette.primary.main}`,
                        paddingLeft: '12px',
                        backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity),
                    },
                }),
            },
        },
    },
};
const LightThemeOptions = { ...BaseThemeOptions, palette: LightThemePalette };
const DarkThemeOptions = { ...BaseThemeOptions, palette: DarkThemePalette };
/**
 * Generation method for generating slightly modified themes.
 * The default themes also make use of this generation method.
 * @param themeConfiguration The configuration of the theme. Will be supplied with default configurations. If no changes are required pass an empty object.
 * @returns Theme
 */
export const generateTheme = ({ variant = 'light', typographyVariant = 'default', }) => {
    const typographyOptions = typographyVariant === 'default' ? ThemeTypographyOptions : AdvertismentTypographyOptions;
    const themeOptions = variant === 'light' ? LightThemeOptions : DarkThemeOptions;
    const mergedOptions = deepmerge(themeOptions, { typography: typographyOptions }, { clone: true });
    return createTheme(mergedOptions);
};
export const LightTheme = generateTheme({});
export const DarkTheme = generateTheme({ variant: 'dark' });
