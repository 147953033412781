import { get, post } from "./api";

const API_PREFIX = "/api/";

export default {
  authenticate: (authDto) =>
    post(`${API_PREFIX}auth/login`, authDto, null, {
      "X-User-Timezone": "Europe/Berlin",
    }),
  getUserInfo: () => get(`${API_PREFIX}auth/info`),
};
