import { combineReducers } from "redux";
import auth from "./auth";
import projects from "./projects";
import detailsDialog from "./details-dialog";
import CIBuildStep from "./CIBuildStep.reducer";
import flashMessageReducer from "./flashMessage.reducer";
import logout from "./logoutPopup.reducer";
import serverConfiguration from "./server-configuration.reducer";
import spinner from "./spinner";
import breadcrumbReducer from './breadCrumb.reducer';

const rootReducer = combineReducers({
  auth,
  projects,
  detailsDialog,
  CIBuildStep,
  flashMessageReducer,
  logout,
  serverConfiguration,
  spinner,
  breadcrumbReducer,
});

export default rootReducer;
