import { SET_BREADCRUMB } from '../actions/breadCrumb.action';

const initialState = {
  breadcrumbs: [],
};

const breadcrumbReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BREADCRUMB:
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    default:
      return state;
  }
};

export default breadcrumbReducer;
