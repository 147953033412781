export const ActionTypes = {
  SHOW_MESSAGE: "SHOW_MESSAGE",
  HIDE_MESSAGE: "HIDE_MESSAGE",
};

const showFlashMessage = (message, type) => ({
  type: ActionTypes.SHOW_MESSAGE,
  payload: {
    flashMessage: message,
    flashMessageType: type,
  },
});

export const hideFlashMessage = () => ({
  type: ActionTypes.HIDE_MESSAGE,
});

/**
 * Displays the defined flash message.
 *
 * @param message as string
 * @param type - currently danger, warning and success are supported.
 *               in case danger is used, the flash message must be closed manually.
 */
export const flashMessage = (message, type) => (dispatch) => {
  dispatch(showFlashMessage(message, type));
  setTimeout(() => {
    dispatch(hideFlashMessage());
  }, getDuration(message));
};

/**
 * Formula: Duration (in ms) = 3000ms + (75ms * length of text)
 *
 * @param message as string
 * @returns duration in ms
 */
const getDuration = (message) =>
  message?.length ? 3000 + 75 * message.length : 3000;
