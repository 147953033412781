import { colors } from "../../../utils/colors";
import styled from "styled-components";
import { appRole } from "../../../utils/roles.utils";

export const CommentCloud_div = styled.div`
  width: max-content;
  max-width: 77%;
  background-color: ${(props) => (props.own ? colors.blue : "white")};
  border: ${(props) => !props.own && "1px solid lightgrey"};
  border-radius: 10px;
  padding: 10px 25px 10px 15px;
  margin-bottom: ${(props) => (props.isLast ? 0 : "10px")};
`;

export const User_p = styled.p`
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 5px;
  margin-right: 10px;
  display: inline;
  color: ${(props) =>
    props.comment.user === appRole.SYSTEM
      ? "rgb(91, 192, 222)"
      : props.own
      ? "white"
      : "black"};
`;

export const Date_p = styled.p`
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 5px;
  display: inline;
  color: ${(props) => (props.own ? "white" : "black")};
`;

export const Comment_p = styled.p`
  color: ${(props) => (props.own ? "white" : "black")};
  white-space: pre-wrap;
  overflow: ${(props) => props.isOverview && "hidden"};
  text-overflow: ${(props) => props.isOverview && "ellipsis"};
`;
