import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle
} from "@mui/material";
import { WbIcon } from "@workbench/react";
import React, { useEffect, useRef, useState } from "react";
import { Input } from "reactstrap";
import BuildOperation from "../../../api/build.operation";
import CIBuildStepApi from "../../../api/CIBuildStep.operation";
import CommentDetails from "../CommentDetails/CommentDetails";
import {
  Chat, closeButton, CommentBox, CommentSection,
  DialogFlexWrapper, ImageFullSize, ImageWrapper, InputWrapper,
  NoComments,
  Title
} from "./CommentPopup.style";
import { appRole } from "../../../utils/roles.utils";

export const CommentPopup = ({
  title,
  userName,
  findingId,
  attachmentId,
  buildStepId,
  buildId,
  sendCallback,
  disabled,
  open,
  image,
  onClose,
  role,
  aagIcons,
  disablecomment
}) => {
  const [comment, setComment] = useState("");
  const [allComments, setAllComments] = useState([]);
  const messagesEndRef = useRef(null);
  useEffect(() => {
    if (open) {
      setAllComments([]);
      fetchData();
      setComment("");
    }
  }, [open]);

  useEffect(() => {
    if (messagesEndRef.current) scrollToBottom();
  }, [allComments]);

  const fetchData = () => {
    getComments().then((comments) => setAllComments(comments));
  };

  const addComment = () => {
    if (comment)
      postComment(comment).then(() => {
        fetchData();
        setComment("");
        sendCallback();
      });
  };

  const getComments = () => {
    if (findingId && buildStepId)
      return CIBuildStepApi.getFindingComments(buildStepId, findingId);
    if (attachmentId && buildStepId)
      return CIBuildStepApi.getAttachmentComments(buildStepId, attachmentId);
    if (buildId) return BuildOperation.getBuildComments(buildId);
    if (buildStepId) return CIBuildStepApi.getBuildStepComments(buildStepId);
  };

  const postComment = (comment) => {
    if (findingId && buildStepId)
      return CIBuildStepApi.postFindingComment(buildStepId, findingId, comment);
    if (attachmentId && buildStepId)
      return CIBuildStepApi.postAttachmentComment(
        buildStepId,
        attachmentId,
        comment
      );
    if (buildId) return BuildOperation.postBuildComment(buildId, comment);
    if (buildStepId)
      return CIBuildStepApi.postBuildStepComment(buildStepId, comment);
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (

    <Dialog open={Boolean(open)} onClose={onClose}>
      <DialogFlexWrapper>
        {image && (
          <ImageWrapper>
            <ImageFullSize src={image} />
          </ImageWrapper>
        )}
        <CommentSection image={image}>
          <DialogActions style={{ padding: 0 }}>
            <Button style={closeButton} variant="contained" onClick={onClose}>
              <WbIcon name="bds/close/24" style={{ color: "white", width: 20 }} />
            </Button>
          </DialogActions>

          <DialogTitle style={{ padding: "27px 40px 0 40px" }}>
            {`Comments for${image ? " image" : ""}:`}
          </DialogTitle>
          <Title>{title}</Title>

          <DialogContent>
            <Chat>
              {allComments && allComments?.map((item, i) => (
                <CommentBox key={i} user={item.user} userName={userName}>
                  <CommentDetails comment={item} own={item.user === userName} />
                </CommentBox>
              ))}
              {allComments && allComments?.length === 0 && (
                <NoComments>There are no Comments</NoComments>
              )}
              <div ref={messagesEndRef} />
            </Chat>
            {!disabled && (
              <InputWrapper className="flexRow">
                <Input
                  type="textarea"
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                  disabled = {disablecomment}
                />
                <Button
                  onClick={addComment}
                  style={{ width: 100, height: "auto" }}
                  variant="contained"
                  disabled={comment.length < 1 || disablecomment}
                >

                  Send
                </Button>
              </InputWrapper>
            )}
          </DialogContent>
        </CommentSection>
      </DialogFlexWrapper>
    </Dialog>
  );
};
