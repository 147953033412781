import "./backdrop-spinner.css"
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { Fragment } from "react";
import { connect } from "react-redux";

const BackdropSpinner = ({ component, isOpen }) => {
  return (
    <Fragment>
      {component}
      <Backdrop className={"backdrop-spinner"} open={isOpen}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isOpen: state.spinner.isOpen,
});

export default connect(mapStateToProps)(BackdropSpinner);
