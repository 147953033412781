import { appRole } from "./roles.utils";
import { useracknowledgementApi } from "../api/useracknowledgement.api";
import history from "../history";

export const userCheckDetails = {
  UNKNOWN: "Unknown error",
  VALID: "Valid user",
  NO_USER: "Invalid or blocked user",
  NO_VALID_DEVELOPER: "None of the developers is a valid user",
  NOT_ALL_DEVElOPERS_VALID: "Not all of the developers are valid users",
  NO_LINE_MANAGER:
    "User defined as line manager is not E3 or higher. User defined as line manager should be at least CEO in legal entity.",
  LINE_MANAGER_NOT_IN_LINE:
    "User defined as line manager is not supervisor of any owner",
  NO_EXCEPTION_MANAGER:
    "User defined as exception manager is not E1 or higher. User defined as exception manager should be at least CEO in legal entity.",
  EXCEPTION_MANAGER_NOT_IN_LINE:
    "User defined as exception manager is not supervisor of any owner",
};

export const checkUserAcknowledgments = async (navRole: string) => {
  if (navRole === appRole.OWNER || navRole === appRole.DEVELOPER) {
    try {
      const ackResponse =
        await useracknowledgementApi.checkUserAcknowledgements();
      if (!ackResponse.accepted) {
        redirectToAcknowledgementsPage();
      }
    } catch (e) {
      redirectToAcknowledgementsPage();
    }
  }
};

const redirectToAcknowledgementsPage = () => {
  history.push("/user/acknowledgements");
}
