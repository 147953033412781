import { blue, green, red, yellow } from '@mercedes-benz/mui5-theme';
import { grey } from '@mui/material/colors';

export const colors = {
  green: green[45],
  red: red[45],
  blue: blue[50],
  orange: yellow[45],
  grey: grey[85],
};
