import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, Input, Label, FormGroup } from "reactstrap";
import GroupButtons, { GButton } from "../GroupButtons/GroupButtons";
import history from "../../history";
import { fetchSuccess } from "../../actions/projects";
import { signInUser, setRole } from "../../actions/auth";
import { displayRole } from "../../utils/roles.utils";

const RolesDialogComponet = ({
  roleRedux,
  userName,
  onLogin,
  fetchSuccess,
  setPickedRole,
  isOpen,
}) => {
  const [role, setRole] = useState(null);

  const getButtons = () => {
    return [GButton("Apply", () => submit())];
  };

  const submit = () => {
    if (role) {
      setPickedRole(role);
      onLogin(userName, role);
      fetchSuccess();
    }
  };
  return (
    <Modal isOpen={isOpen} fade={false} centered>
      <ModalBody>
        <FormGroup style={{ paddingBottom: 0, marginBottom: 0 }}>
          <Label for="exampleSelectMulti">
            Please select role to continue with:
          </Label>
          <Input
            onChange={(e) => setRole(e.target.value)}
            type="select"
            name="selectMulti"
            id="exampleSelectMulti"
            multiple
            style={{ overflowY: "auto" }}
          >
            {roleRedux.map((item, i) => (
              <option value={item} key={i}>
                {displayRole[item]}
              </option>
            ))}
          </Input>
          <GroupButtons
            data={getButtons()}
            buttonStyle={{
              marginLeft: 10,
              marginTop: 15,
              width: 100,
            }}
          />
        </FormGroup>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  roleRedux: state.auth.role,
  userName: state.auth.userName,
});

const mapDispatchToProps = (dispatch) => ({
  setPickedRole: (hasMultipleRoles) => {
    dispatch(setRole(hasMultipleRoles));
  },
  onLogin: (userName, role) => dispatch(signInUser(userName, role)),
  fetchSuccess: () => dispatch(fetchSuccess()),
});

export const RolesDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesDialogComponet);
