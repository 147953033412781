export const breadcrumbMapping = {
  admin: 'Admin',
  prepare:'Prepare',
  legal: 'Legal',
  bd: 'Brand Design',
  sec :'Security',
  foss :'FOSS',
  cdp:'Data Protection',
  aag:'App Store Account Governance',
  gd :'General Deliverables',
  user:'GlobalUserAdministration',
  usermaintenanceglobaluser:'GlobalUserMaintenance',
  usermaintenanceprojectuser:'ProjectUserMaintenance',
  appconfiguration:'AppConfiguration',
  linksconfiguration:'LinkConfiguration',
  s3storagemonitoring:'S3StorageMonitoring',
  acknowledgements:"Acknowledgements",
  scanprofile:"ScanProfile",
  builds : "Scan OverView",
  build : "App Approval"
};

export const applicationTypeMapping = {
  EXTERNAL_APP_STORE : 'External App',
  INTERNAL_APP : 'Internal App',
  CAR_APP  : 'AI Custom Model',
  LLM  : 'AI USE CASE',
  WEBPAGE : 'AI Foundation Model'
}
