import styled from "styled-components";

export const InputWrapper = styled.div`
  height: 50px;
`;

export const NoComments = styled.p`
  margin: 10px;
  font-size: 18px;
  color: grey;
`;

export const Title = styled.p`
  margin: 10px 40px 5px 40px;
  font-size: 18px;
`;

export const Chat = styled.div`
  height: 500px;
  overflow-x: auto;
  border-radius: 0.25rem;
  background-color: rgb(250, 250, 250);
  padding: 5px;
`;

export const CommentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.user === props.userName ? "flex-end" : "flex-start"};
`;

export const closeButton = {
  position: "absolute",
  top: 0,
  right: 0,
  margin: 0,
  minWidth: 40,
  minHeight: 40,
  padding: 0,
  backgroundColor: "black",
};

export const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  max-width: 50%;
  width: 600px;
  height: 691px;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border-right: 1px solid rgb(240, 240, 240);
`;

export const CommentSection = styled.div`
  flex: 1;
  max-width: ${(props) => props.image && "50%"};
  width: 600px;
`;

export const ImageFullSize = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

export const DialogFlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
