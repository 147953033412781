import massp from "../api/massp";

export const ActionTypes = {
  USER_SIGNED_IN: "USER_SIGNED_IN",
  USER_SIGNED_OUT: "USER_SIGNED_OUT",
  SET_ROLE: "SET_ROLE",
  SET_ROLES: "SET_ROLES",
  SET_GLOBAL_ROLES: "SET_GLOBAL_ROLES",
  SET_USER_NAME: "SET_USER_NAME",
  SET_IS_ADMIN: "SET_IS_ADMIN",
  PERFORME_UPDATE: "PERFORME_UPDATE",
  UPDATE_DONE: "UPDATE_DONE",
  SET_APPLICATION_TYPE : "SET_APPLICATION_TYPE",
  SET_ISAPPLICATIONTYPE_SELECTED : "SET_ISAPPLICATIONTYPE_SELECTED"
};

const userSignedIn = (userName, role) => ({
  type: ActionTypes.USER_SIGNED_IN,
  payload: { userName, role },
});

const userSignedOut = () => ({
  type: ActionTypes.USER_SIGNED_OUT,
});

export const setRole = (role) => ({
  type: ActionTypes.SET_ROLE,
  payload: role,
});

export const setRoles = (roles) => ({
  type: ActionTypes.SET_ROLES,
  payload: roles,
});

export const setGlobalRoles = (roles) => ({
  type: ActionTypes.SET_GLOBAL_ROLES,
  payload: roles,
});

export const setUserName = (userName) => ({
  type: ActionTypes.SET_USER_NAME,
  payload: userName,
});

export const setIsAdmin = (isAdmin) => ({
  type: ActionTypes.SET_IS_ADMIN,
  payload: isAdmin,
});

export const setApplicationType = (applicationType) => ({
  type : ActionTypes.SET_APPLICATION_TYPE,
  payload:applicationType
})
export const setIsApplicationTypeSelected = (isApplicationTypeSelected) => ({
  type : ActionTypes.SET_ISAPPLICATIONTYPE_SELECTED,
  payload: isApplicationTypeSelected
})
export const performAuthUpdate = () => ({
  type: ActionTypes.PERFORME_UPDATE,
});

export const updateAuthDone = () => ({
  type: ActionTypes.UPDATE_DONE,
});

export const signInUser = (userName, role) => (dispatch) => {
  if (userName != null && role != null) {
    dispatch(userSignedIn(userName, role));
  } else {
    window.location = "/logout";
  }
};

export const signOutUser = () => (dispatch) => {
  massp.getAuthConfig().then((result) => {
    if (result) {
      dispatch(userSignedOut());
      window.location.href = result.logoutUrl;
    }
  });
};
