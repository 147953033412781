import * as React from "react";
import { ConfirmPopup } from "../ConfirmPopup/ConfirmPopup";

const ConfirmationServiceContext = React.createContext(Promise.reject);

export const useConfirmation = () =>
  React.useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = React.useState(null);

  const awaitingPromiseRef = React.useRef();

  const openConfirmation = (options) => {
    setConfirmationState(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
    window.removeEventListener("popstate", handleClose);
  };

if (confirmationState) {
    window.addEventListener("popstate", handleClose);
}

  const handleSubmit = (message) => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(message);
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      <ConfirmPopup
        open={Boolean(confirmationState)}
        onSubmit={(comment) => handleSubmit(comment)}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  );
};
