export const SpinnerActions = {
  SHOW_SPINNER: "SHOW_SPINNER",
  HIDE_SPINNER: "HIDE_SPINNER",
};

export const showSpinner = () => ({
  type: SpinnerActions.SHOW_SPINNER,
});

export const hideSpinner = () => ({
  type: SpinnerActions.HIDE_SPINNER,
});
