export const saveAs = (filename, file) => {
  if (window.navigator.msSaveBlob) {
    return window.navigator.msSaveBlob(file, filename);
  }
  const element = document.createElement("a");
  element.setAttribute("href", URL.createObjectURL(file));
  element.setAttribute("download", filename);
  element.setAttribute("target", "_blank");

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export function formatBytes(a, b = 2) {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
}

export const extractFileNameFromResponse = response => {
  return response.headers["content-disposition"]?.split("filename=")[1]?.slice(1, -1);
};