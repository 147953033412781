import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { appRole } from "../../utils/roles.utils";
import BackdropSpinner from "../../components/backdrop-spinner/backdrop-spinner";
import NotAuthorized from "../NotAuthorized/NotAuthorized";

const UnknownUserPage = React.lazy(() =>
  import("../unknown-user-page/unknown-user-page")
);

const PrivateRoute = ({
  auth,
  isAdmin,
  fluid,
  component: Component,
  serverConfiguration,
  approvalComponent: ApprovalComponent,
  ...rest
}) => {
  const renderComponent = (props) => {
    if (
    ( rest.path !== ("/home") && (auth.role === appRole.OWNER ||
      auth.role === appRole.ADMIN ||
      auth.role === appRole.DEVELOPER ||
      auth.role === appRole.CERTIFICATION_AUTHORITY ||
      auth.role === appRole.PROJECT_CREATOR)) || (rest.path === ("/home")) && (serverConfiguration.scanProfileEnabled)
    ) {
      return <Component {...props} />;
    } 
    else if(
      ((rest.path === ("/bd/:projectId/:buildStepId")) && (auth.role !== appRole.BRAND_DESIGN_APPROVER)) ||
      ((rest.path === ("/legal/:projectId/:buildStepId")) && (auth.role !== appRole.LEGAL_APPROVER)) ||
      ((rest.path === ("/cdp/:projectId/:buildStepId")) && (auth.role !== appRole.CDP_APPROVER))  ||
      ((rest.path === ("/aag/:projectId/:buildStepId")) && (auth.role !== appRole.AAG_APPROVER)) || 
      ((rest.path === ("/home")) && (!serverConfiguration.scanProfileEnabled))
    ){
      return <NotAuthorized/>
    }
    else if (
      auth.role === appRole.BRAND_DESIGN_APPROVER ||
      auth.role === appRole.LEGAL_APPROVER ||
      auth.role === appRole.CDP_APPROVER ||
      auth.role === appRole.LINE_MANAGER ||
      auth.role === appRole.EXCEPTION_APPROVER ||
      auth.role === appRole.ACCOUNT_MANAGER ||
      auth.role === appRole.AAG_APPROVER
    ) {
      return <ApprovalComponent {...props} />;
    }

    if (auth.isAuthenticated && auth.role) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location.pathname },
          }}
        />
      );
    }
    return <UnknownUserPage />;
  };

  return (
    <Route
      {...rest}
      render={(props) => <BackdropSpinner component={renderComponent(props)} />}
    />
  );
};

const mapStateToProps = (state) => ({
  serverConfiguration: state.serverConfiguration,
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
