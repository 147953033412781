import React, { useState } from "react";
import * as yup from "yup";
import styled from "styled-components";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import GroupButtons, { GButton } from "../GroupButtons/GroupButtons";

export const ConfirmPopup = ({
  open,
  message,
  customClassName,
  onSubmit,
  onClose,
  input,
  customElement,
  closeButtonLabel,
  confirm = true,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);

  const getButtons = () => {
    const buttons = [];
    buttons.push(
      GButton(
         closeButtonLabel ?? "Back",
        () => {
          onClose();
          clear();
        },
        "outlined"
      )
    );
    if (confirm)
      buttons.push(
        GButton("Confirm", () => {
          if (input)
            popupCommentSchema
              .validate({ inputValue })
              .then(() => {
                onSubmit(inputValue);
                clear();
              })
              .catch((e) => setError(e.message));
          else {
            onSubmit();
            clear();
          }
        })
      );

    return buttons;
  };

  const clear = () => {
    setInputValue("");
    setError("");
    setIsValid(false);
  };

  const getValidation = () => {
    if (input?.validation) {
      return yup
        .string()
        .required(`${input.label} is required.`)
        .matches(
          new RegExp(input.validation),
          `${input.label} ${input.validationText}`
        );
    }
    return yup
      .string()
        // seems to be reasonable for a general rule/ limit
      .max(1000, "Max 1000 characters allowed");
  };

  const popupCommentSchema = yup.object().shape({
    inputValue: getValidation(),
  });

  const handleOnChange = (value) => {
    popupCommentSchema.isValid({ inputValue: value }).then((res) => {
      setInputValue(value);
      if (res !== isValid) setIsValid(res);
      if (error) setError(null);
    });
  };

  return open ? (
    <Dialog
      open={Boolean(open)}
      onClose={onClose}
      className={customClassName}
    >
      <DialogContent
        style={{
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DialogContentText
          style={{
            flex: 1,
            overflowY: "auto",
          }}
        >
          <Content>{message}</Content>
        </DialogContentText>
        {input && (
          <TextField
            type="textarea"
            label={input.placeholder}
            onChange={(e) => handleOnChange(e.target.value)}
            value={inputValue}
            style={{
              width: "100%",
            }}
            variant="filled"
            multiline={input.multiline ?? false}
            rows={input.multiline ? 3 : 1}
            helperText={error}
            error={error}
          />
        )}
        {customElement}
        <GroupButtons
          style={{ marginTop: 20 }}
          data={getButtons()}
          buttonStyle={groupButtonsStyle}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <></>
  );
};

const groupButtonsStyle = { marginLeft: 10, width: 100 };

const ErrorMessage = styled.div`
  margin-left: 5px;
  margin-bottom: 0px;
  color: #dc3545;
`;

const Content = styled.span`
  white-space: pre-wrap;
  word-wrap: break-word;
`;
