import moment, { Moment } from "moment";

const germanDateTimeFormat = "DD.MM.YYYY HH:mm:ss";
const germanDateFormat = "DD.MM.YYYY";
const usDateFormat = "YYYY-MM-DD";

export const utcToLocale = (dateString: string): string => {
  return utcToLocaleDateString(dateString, germanDateTimeFormat);
};

export const utcToLocaleDate = (dateString: string): string => {
  return utcToLocaleDateString(dateString, germanDateFormat);
};

const utcToLocaleDateString = (dateString: string, dateFormat: string) => {
  const utcDate = moment.utc(dateString).toDate();
  return moment(utcDate).local().format(dateFormat);
};

export const timestampToDate = (dateString: string): string | null => {
  return dateString ? moment(dateString).format(usDateFormat) : null;
};

export const isLeapDay = (date: Moment): boolean => {
  return date ? date.isSame(`${date.year()}-02-29`, "day") : false;
};
