import axios from "axios";
import { store } from "../index";
import { flashMessage } from "../actions/flashMessage.actions";
import { fetchSuccess } from "../actions/projects";
import history from "../history";
import i18next from "i18next";
import { hideSpinner } from "../actions/spinner.action";
import { GET, POST, PUT, DELETE } from "http-constants/src/main/consts-methods";
import { BAD_REQUEST, FORBIDDEN, METHOD_NOT_ALLOWED, UNAUTHORIZED } from "http-constants/src/main/consts-codes";

const API_PREFIX = "/api/projectdocument";
const axiosCore = (type, url, data, contentType, headers, params,
    onlyReturnData = true) => {
  return axios({
    url: `${url}`,
    method: type,
    headers: {
      "Content-Type": contentType || "application/json",
      "X-Nav-Role": store.getState().auth.role,
      "X-Division": "Mercedes-Benz",
      ...headers,
    },
    data: contentType ? data : JSON.stringify(data),
    withCredentials: true,
    ...params,
  })
    .then((res) => {
      return onlyReturnData ? res.data : res;
    })
    .catch((e) => {
      store.dispatch(hideSpinner());
      if (e.response) {
        if (e.response.status === BAD_REQUEST) {
          let message = i18next.t("app-data-not-valid");
          if (e.response.data.debugMessage) {
            message = e.response.data.debugMessage;
          } else if (e.response.data.message) {
            message = e.response.data.message;
          } else if (!onlyReturnData && url.toString().includes(API_PREFIX)) {
            message = i18next.t("s3-storage-key-not-exist");
          }
          store.dispatch(
            flashMessage(
              i18next.t("buildApproval-flashMessage-request-failed") + message,
              "danger",
              5000
            )
          );
          return Promise.reject(e.response);
        }
        if (e.response.status === UNAUTHORIZED) {
          if (e.response.headers.location != null) {
            window.location.href = e.response.headers.location;
            return e.response;
          }
        } else if (e.response.status === FORBIDDEN) {
          store.dispatch(fetchSuccess());
          history.push("/notAuthorized");
          return Promise.reject(e.response);
        } else if (e.response.status >= METHOD_NOT_ALLOWED) {
          store.dispatch(
            flashMessage(i18next.t("api-no-server"), "danger", 5000)
          );
          return Promise.reject(e);
        } else {
          return Promise.reject(e.response);
        }
      } else {
        store.dispatch(
          flashMessage(i18next.t("api-no-server"), "danger", 5000)
        );
        return Promise.reject(e);
      }
    });
};
export const get = (url, data, contentType, headers, params) =>
  axiosCore(GET, url, data, contentType, headers, params);

export const getRaw = (url, data, contentType, headers, params) =>
  axiosCore(GET, url, data, contentType, headers, params, false);

export const post = (url, data, contentType, headers, params) =>
  axiosCore(POST, url, data, contentType, headers, params);

export const put = (url, data, contentType, headers, params) =>
  axiosCore(PUT, url, data, contentType, headers, params);

export const remove = (url, data, contentType, headers, params) =>
  axiosCore(DELETE, url, data, contentType, headers, params);
