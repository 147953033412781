import i18next from "i18next";
import massp from "../api/massp";
import { signOutUser } from "./auth";
import {flashMessage} from "./flashMessage.actions";
import { hideSpinner, showSpinner } from "./spinner.action";

const handleError = (dispatch, action) => (status) => {
  if (status === 403) {
    dispatch(hideSpinner());
    dispatch(signOutUser());
  }

  const error = status ? `Request Failed: ${status}` : "Request Failed";
  dispatch(action(error));
};

export const ActionTypes = {
  INIT_PROJECTS_STATE: "INIT_PROJECTS_STATE",
  FETCH_PROJECTS_REQUEST: "FETCH_PROJECTS_REQUEST",
  FETCH_PROJECTS_REQUEST_BACKGROUND: "FETCH_PROJECTS_REQUEST_BACKGROUND",
  FETCH_FILTERED_PROJECTS_REQUEST: "FETCH_FILTERED_PROJECTS_REQUEST",
  FETCH_PROJECTS_FAILURE: "FETCH_PROJECTS_FAILURE",
  FETCH_PROJECTS_SUCCESS: "FETCH_PROJECTS_SUCCESS",
  FETCH_DATA: "FETCH_DATA",
  FETCH_SUCCESS: "FETCH_SUCCESS",
  CHECK_PROJECT_REQUEST: "CHECK_PROJECT_REQUEST",
  CANCEL_PROJECT_REQUEST: "CANCEL_PROJECT_REQUEST",
  CHECK_PROJECT_FAILURE: "CHECK_PROJECT_FAILURE",
  CANCEL_PROJECT_FAILURE: "CANCEL_PROJECT_FAILURE",
  CHECK_PROJECT_SUCCESS: "CHECK_PROJECT_SUCCESS",
  CANCEL_PROJECT_SUCCESS: "CANCEL_PROJECT_SUCCESS",
  SET_ERROR_WITH_MESSAGE: "SET_ERROR_WITH_MESSAGE",
  SET_PROJECT_SEARCH_FILTER: "SET_PROJECT_SEARCH_FILTER",
};

export const initProjectsState = (projectSearchFilter) => ({
  type: ActionTypes.INIT_PROJECTS_STATE,
  payload:projectSearchFilter
});

const fetchProjectsRequest = () => ({
  type: ActionTypes.FETCH_PROJECTS_REQUEST,
});

const fetchProjectsRequestInBackground = () => ({
  type: ActionTypes.FETCH_PROJECTS_REQUEST_BACKGROUND,
});

const fetchFilteredProjectsRequest = () => ({
  type: ActionTypes.FETCH_FILTERED_PROJECTS_REQUEST,
});

const fetchProjectsSuccess = (projects) => ({
  type: ActionTypes.FETCH_PROJECTS_SUCCESS,
  projects,
});

const fetchProjectsFailure = (error) => ({
  type: ActionTypes.FETCH_PROJECTS_FAILURE,
  error,
});

const checkProjectRequest = () => ({
  type: ActionTypes.CHECK_PROJECT_REQUEST,
});

const cancelBuildRequest = () => ({
  type: ActionTypes.CANCEL_PROJECT_REQUEST,
});

const checkProjectSuccess = () => ({
  type: ActionTypes.CHECK_PROJECT_SUCCESS,
});

const cancelBuildSuccess = () => ({
  type: ActionTypes.CANCEL_PROJECT_SUCCESS,
});

const checkProjectFailure = (error) => ({
  type: ActionTypes.CHECK_PROJECT_FAILURE,
  error,
});

const cancelBuildFailure = (error) => ({
  type: ActionTypes.CANCEL_PROJECT_FAILURE,
  error,
});

export const fetchData = () => ({
  type: ActionTypes.FETCH_DATA,
});

export const fetchSuccess = () => ({
  type: ActionTypes.FETCH_SUCCESS,
});

export const setErrorWithMessage = (message) => ({
  type: ActionTypes.SET_ERROR_WITH_MESSAGE,
  message,
});

export const setProjectSearchFilter = (filterTerm) => ({
  type: ActionTypes.SET_PROJECT_SEARCH_FILTER,
  filterTerm: filterTerm,
});

export const getAllProjects = (isBackground, applicationType) => (dispatch) => {
  if (isBackground) {
    dispatch(fetchProjectsRequestInBackground());
  } else {
    dispatch(showSpinner());
    dispatch(fetchProjectsRequest());
  }
  massp.getAll(applicationType).then(
    (projects) => {
      if (projects) {
        dispatch(hideSpinner());
        dispatch(fetchProjectsSuccess(projects));
      }
    },
    () => handleError(dispatch, fetchProjectsFailure)
  );
};

export const getFilteredProjects = (query, applicationType) => (dispatch) => {
  dispatch(showSpinner());
  dispatch(fetchFilteredProjectsRequest());
  massp.getFilteredProjects(query, applicationType).then(
    (projects) => {
      if (projects) {
        dispatch(hideSpinner());
        dispatch(fetchProjectsSuccess(projects));
      }
    },
    () => handleError(dispatch, fetchProjectsFailure)
  );
};

export const getProjectBuilds = (projectId, isBackground) => (dispatch) => {
  if (isBackground) {
    dispatch(fetchProjectsRequestInBackground());
  } else {
    dispatch(showSpinner());
    dispatch(fetchProjectsRequest());
  }
  massp.getProjectBuilds(projectId).then(
    (project) => {
      if (project) {
        dispatch(hideSpinner());
        dispatch(fetchProjectsSuccess([project]));
      }
    },
    () => handleError(dispatch, fetchProjectsFailure)
  );
};

export const checkProject = (projectId, showBuilds, query, applicationType) => (dispatch) => {
  dispatch(checkProjectRequest());
  handleCheck(projectId, showBuilds, dispatch, query, applicationType);
};

async function handleCheck(projectId, showBuilds, dispatch, query, applicationType) {
  try {
    await massp.check(projectId);
    if (showBuilds) {
      dispatch(getProjectBuilds(projectId, false));
    } else {
      if (query) {
        dispatch(getFilteredProjects(query, applicationType));
        const getProjectsbyQuery = () => {massp.getFilteredProjects(query, applicationType).then(
          (projects) => {
            if (projects) {
              dispatch(hideSpinner());
              dispatch(fetchProjectsSuccess(projects));
              const findObj = projects.findIndex((item) => item.projectId === projectId)
              if(projects[findObj].lastBuild.buildSteps[0].stepStatus !== 'RUNNING'){
                clearInterval(intervalId)
              }
            }
          },
          () => handleError(dispatch, fetchProjectsFailure)
        );
      };
        const intervalId = setInterval(() => {
          getProjectsbyQuery();
        }, 3000);
      } else {
        dispatch(getAllProjects(false, applicationType));
        const getProjects = () => {
          massp.getAll(applicationType).then(
          (projects) => {
            if (projects) {
              dispatch(hideSpinner());
              dispatch(fetchProjectsSuccess(projects));
              const findObj = projects.findIndex((item) => item.projectId === projectId)
              if(projects[findObj].lastBuild.buildSteps[0].stepStatus !== 'RUNNING'){
                clearInterval(intervalId)
              }
            }
          },
          () => handleError(dispatch, fetchProjectsFailure)
        );
      };
        const intervalId = setInterval(() => {
          getProjects();
        }, 3000);
      }
    }
    dispatch(checkProjectSuccess());
  } catch (error) {
    if (error.request?.status === 400) {
      dispatch(flashMessage(i18next.t("run-checks-is-not-possible"), "danger"));
    }
    handleError(dispatch, checkProjectFailure);
  }
}

export const cancelBuild = (projectId, showBuilds, applicationType) => (dispatch) => {
  dispatch(cancelBuildRequest());
  massp.cancel(projectId).then(
    () => {
      if (showBuilds) {
        dispatch(getProjectBuilds(projectId, false));
      } else {
        dispatch(getAllProjects(false, applicationType));
      }
      dispatch(cancelBuildSuccess());
    },
    () => handleError(dispatch, cancelBuildFailure)
  );
};
