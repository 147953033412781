import React from "react";
import { Comment_p, CommentCloud_div, Date_p, User_p } from "./CommentDetails.style";
import { utcToLocale } from "../../../utils/DateUtil";

const CommentDetails = ({ comment, isLast, own, isOverview }) => {
  return (
    <CommentCloud_div
      isLast={isLast}
      own={own}
      title={isOverview && comment.text}
    >
      <User_p own={own} comment={comment}>
        {comment.user}
      </User_p>
      <Date_p own={own}>{utcToLocale(comment.createdAt)}</Date_p>
      <Comment_p own={own} isOverview={isOverview}>
        {comment.text}
      </Comment_p>
    </CommentCloud_div>
  );
};

export default CommentDetails;
