export const displayRole = {
    OWNER: "Owner",
    ADMIN: "Admin",
    REPORTING: "Reporting",
    DEVELOPER: "Developer",
    TECHNICAL: "Technical",
    TECHNICAL_LDSSO: "Technical LDSSO",
    SYSTEM: "System",
    BUILD_APPROVER: "Build Approver",
    BRAND_DESIGN_APPROVER: "Brand Design Approver",
    LEGAL_APPROVER: "Legal Approver",
    CDP_APPROVER: "Data Protection Approver",
    APPROVER: "Approver",
    LINE_MANAGER: "Line Manager",
    EXCEPTION_APPROVER: "Exception Manager",
    ACCOUNT_MANAGER: "Account Manager",
    CERTIFICATION_AUTHORITY: "Certification Authority",
    AAG_APPROVER:"AAG Approver",
};

export const appRole = {
    OWNER: "OWNER",
    ADMIN: "ADMIN",
    PROJECT_CREATOR: "PROJECT_CREATOR",
    REPORTING: "REPORTING",
    DEVELOPER: "DEVELOPER",
    TECHNICAL: "TECHNICAL",
    TECHNICAL_LDSSO: "TECHNICAL_LDSSO",
    SYSTEM: "SYSTEM",
    BUILD_APPROVER: "BUILD_APPROVER",
    BRAND_DESIGN_APPROVER: "BRAND_DESIGN_APPROVER",
    LEGAL_APPROVER: "LEGAL_APPROVER",
    CDP_APPROVER: "CDP_APPROVER",
    APPROVER: "APPROVER",
    LINE_MANAGER: "LINE_MANAGER",
    EXCEPTION_APPROVER: "EXCEPTION_APPROVER",
    ACCOUNT_MANAGER: "ACCOUNT_MANAGER",
    CERTIFICATION_AUTHORITY: "CERTIFICATION_AUTHORITY",
    AAG_APPROVER: "AAG_APPROVER",
};

export const navigationRoles = [
    "OWNER",
    "ADMIN",
    "DEVELOPER",
    "BUILD_APPROVER",
    "BRAND_DESIGN_APPROVER",
    "LEGAL_APPROVER",
    "CDP_APPROVER",
    "LINE_MANAGER",
    "EXCEPTION_APPROVER",
    "ACCOUNT_MANAGER",
    "CERTIFICATION_AUTHORITY",
    "AAG_APPROVER",
];

export const managementLevel = {
    E0: {label: "E0 or must be CEO in legal entity", level: "000", subLevels: ["000"]},
    E1: {label: "E1 or must be CEO in legal entity", level: "001", subLevels: ["000", "001"]},
    E2: {label: "E2 or must be CEO in legal entity", level: "002", subLevels: ["000", "001", "002"]},
    E3: {label: "E3 or must be CEO in legal entity", level: "003", subLevels: ["000", "001", "002", "003"]},
    E4: {label: "E4 or must be CEO in legal entity", level: "004", subLevels: ["000", "001", "002", "003", "004"]}
};

export const isStepApproverRole = (role: string) => {
    return (
        role === appRole.BRAND_DESIGN_APPROVER ||
        role === appRole.LEGAL_APPROVER ||
        role === appRole.CDP_APPROVER ||
        role === appRole.AAG_APPROVER
    );
}

export const isBuildApproverRole = (role: string) => {
    return (
        role === appRole.LINE_MANAGER ||
        role === appRole.EXCEPTION_APPROVER ||
        role === appRole.ACCOUNT_MANAGER
    );
}

export const isProjectMaintainer = (role: string) => {
    return (
      role === appRole.OWNER ||
      role === appRole.DEVELOPER ||
      role === appRole.PROJECT_CREATOR
    );
}
