import React from "react";
import {Button} from "@mui/material";
import LightTooltip from "../light-tooltip";

export const GButton = (label, onClick, variant, style, disable, tooltip) => ({
    label,
    onClick,
    variant,
    style,
    disable,
    tooltip
});

const GroupButtons = ({data, buttonStyle, style}) => (
    <div
        style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            ...style,
        }}
    >
        {data &&
        data.map((item, index) => (
            item.tooltip ? createButtonWithTooltip(item, index, buttonStyle) : createButton(item, index, buttonStyle)
        ))}
    </div>
);

const createButton = (item, index, buttonStyle) => {
    return (
        <Button
            key={index}
            className={"action-button"}
            style={{...buttonStyle, ...item.style}}
            onClick={item.onClick}
            variant={item.variant || "contained"}
            disabled={item.disable}
        >
            {item.label}
        </Button>
    )
};

const createButtonWithTooltip = (item, index, buttonStyle) => {
    return (
        <LightTooltip title={item.tooltip}>
            <span>
                {createButton(item, index, buttonStyle)}
            </span>
        </LightTooltip>
    )
};

export default GroupButtons;
