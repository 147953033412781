import React from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, Label } from "reactstrap";
import GroupButtons, { GButton } from "../GroupButtons/GroupButtons";
import { hideLogoutPopup } from "../../actions/logoutPopup.actions";
import { signOutUser } from "../../actions/auth";

const LogoutPopupComponent = ({ hideLogoutPopup, onLogout, isOpen }) => {
  const getButtons = () => {
    return [
      GButton("Back", () => hideLogoutPopup(), "outlined"),
      GButton("Logout", () => {
        onLogout();
        hideLogoutPopup();
      }),
    ];
  };

  return (
    <Modal isOpen={isOpen} fade={false} centered>
      <ModalBody>
        <Label>
          You will be logged out from all Mercedes-Benz applications. Continue with
          logout?
        </Label>
        <GroupButtons
          data={getButtons()}
          buttonStyle={{ marginLeft: 10, marginTop: 15}}
        />
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  roleRedux: state.auth.role,
  userName: state.auth.userName,
});
const mapDispatchToProps = (dispatch) => ({
  hideLogoutPopup: () => dispatch(hideLogoutPopup()),
  onLogout: () => dispatch(signOutUser()),
});

export const LogoutPopup = connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutPopupComponent);
