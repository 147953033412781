import { ActionTypes } from "../actions/logoutPopup.actions";

const initialState = {
  showLogoutPopup: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_POPUP: {
      return {
        ...state,
        showLogoutPopup: true,
      };
    }
    case ActionTypes.HIDE_POPUP: {
      return {
        ...state,
        showLogoutPopup: false,
      };
    }
    default:
      return state;
  }
};
