import { Component } from "react";
import { withRouter } from "react-router-dom";

/* 
  The component is not implemented as functional component with useEffect,
  because the componentDidUpload implementation is faster and feels better.
 */
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const { state } = this.props.location;
      const scrollToElement = state && state.scrollTo != null;
      if (!scrollToElement) {
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
