import { get, put, post } from "./api";

const API_PREFIX = "/api/";

export default {
  getApprovalWorkList: () => get(`${API_PREFIX}build/openapprovals`),
  getApprovalArchiveList: (page) => get(`${API_PREFIX}build/closedapprovals?page=${page}`),
  setBuildApprovalStatus: (buildId, approvalStatus) =>
    put(`${API_PREFIX}build/${buildId}/status`, null, null, null, {
      params: { status: approvalStatus },
    }),
  getApproval: (buildId) => get(`${API_PREFIX}build/${buildId}/approval`),
  updateBuildApproval: (buildId, approval) =>
    put(`${API_PREFIX}build/${buildId}/approval`, approval),
  getReportPDF: (buildId) =>
    get(`${API_PREFIX}build/${buildId}/report`, null, null, null, {
      responseType: "blob",
    }),
  getReportExcel: (buildId) =>
    get(`${API_PREFIX}build/${buildId}/export/excel`, null, null, null, {
      responseType: "blob",
    }),
  getBuildComments: (buildId) => get(`${API_PREFIX}build/${buildId}/comments`),
  postBuildComment: (buildId, comment) =>
    post(`${API_PREFIX}build/${buildId}/comment`, comment, "text/plain"),
};
