import { ActionTypes } from "../actions/details-dialog";

export default function detailsDialog(state = null, action) {
  switch (action.type) {
    case ActionTypes.OPEN_DETAILS_DIALOG:
      return action.buildStep;

    case ActionTypes.CLOSE_DETAILS_DIALOG:
      return null;

    default:
      return state;
  }
}
