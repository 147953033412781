import { SpinnerActions } from "../actions/spinner.action";

const initialState = {
  isOpen: false,
};

export default function spinner(state = initialState, action) {
  switch (action.type) {
    case SpinnerActions.SHOW_SPINNER:
      return { ...state, isOpen: true };
    case SpinnerActions.HIDE_SPINNER:
      return { ...state, isOpen: false };
    default:
      return state;
  }
}
