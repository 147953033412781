import {breadcrumbMapping,applicationTypeMapping} from '../utils/breadCrumbs.utils';

export const SET_BREADCRUMB = 'SET_BREADCRUMB';

export const setBreadcrumb = (breadcrumb) => ({
  type: SET_BREADCRUMB,
  payload: breadcrumb,
});

const generateLabel = (segment) => {
  return breadcrumbMapping[segment.includes('admin') ? segment[1] : segment[0]] || segment;
};

const appNameConversion = (applicationType) =>{
  return applicationTypeMapping[applicationType]
}

export const generateBreadcrumb = (url,applicationType) => {
  return (dispatch) => {
    const urlSegments = url.split('/').filter(Boolean);
    let breadcrumb = [];
    breadcrumb.push({ label:'Home', url: '/home' },
    { label: appNameConversion(applicationType), url: '/projectsOverview' })
    if (urlSegments[0] !== ('projectsOverview')) {
          breadcrumb.push({
            label: generateLabel(urlSegments),
            url: url,
          });
    }
    dispatch(setBreadcrumb(breadcrumb));
  };
};

