import { ConfigurationActions } from "../actions/server-configuration.action";

const initialState = {
  gemsDisabled: true,
  // Feature Toggle (deprecated after finishing MASSP-1344)
  scanProfileEnabled: false,
  externalLinks: []
};

export default function serverConfiguration(state = initialState, action) {
  switch (action.type) {
    case ConfigurationActions.CONFIGURATION_FETCHED:
      return action.serverConfiguration;
    default:
      return state;
  }
}
