import styled from "styled-components";

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MessageParagraph = styled.p`
  color: white;
  margin: 0 15px;
`;

export const SnackbarAnchor = {
  vertical: "top",
  horizontal: "left",
};

export const SnackbarContentProps = {
  classes: {
    root: {
      background: "red",
    },
  },
};

export const closeMessageStyle = { color: "white" };

export const SnackbarContentStyle = (flashMessageType, colors) => ({
  backgroundColor: flashMessageType === "danger" ? colors.red :
      flashMessageType === "warning" ? colors.orange : colors.green
});
