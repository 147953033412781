import React from "react";
import "./NotAuthorized.css";

export default () => {
  return (
    <div className="outer-container">
      <div className="inner-container">
        <h1 className="header">403</h1>
        <h4>Access denied</h4>
        <p className="text">
          Please contact <span className="mail-link">massp@mercedes-benz.com</span>{" "}
          for accessing the Mobile App Self Service Platform
        </p>
      </div>
    </div>
  );
};
