import { ActionTypes } from "../actions/auth";
import { appRole } from "../utils/roles.utils";

const initialState = {
  isAuthenticated: false,
  userName: null,
  role: null,
  roles: [],
  globalRoles: [],
  isProjectCreator: false,
  isAdmin: null,
  update: false,
  applicationType:"",
  isApplicationTypeSelected:false
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.USER_SIGNED_IN: {
      return {
        ...state,
        isAuthenticated: true,
        userName: action.payload.userName,
        role: action.payload.role,
      };
    }

    case ActionTypes.USER_SIGNED_OUT:
      return initialState;

    case ActionTypes.SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };

    case ActionTypes.SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    case ActionTypes.SET_GLOBAL_ROLES:
      return {
        ...state,
        globalRoles: action.payload,
        isProjectCreator:
          action.payload.includes(appRole.ADMIN) ||
          action.payload.includes(appRole.PROJECT_CREATOR),
      };

    case ActionTypes.SET_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };

    case ActionTypes.SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      };
    case ActionTypes.SET_APPLICATION_TYPE:
      return {
        ...state,
        applicationType : action.payload
      };
    case ActionTypes.SET_ISAPPLICATIONTYPE_SELECTED:
      return{
        ...state,
        isApplicationTypeSelected: action.payload
      };
    case ActionTypes.PERFORME_UPDATE:
      return {
        ...state,
        update: true,
      };

    case ActionTypes.UPDATE_DONE:
      return {
        ...state,
        update: false,
      };

    default:
      return state;
  }
}
