import { get, getRaw, post, put, remove } from "./api";
import BuildStepApprovalDetail from "../data/BuildStepApprovalDetail";
import { ProjectInfo } from "../data/ProjectInfo";
import {BuildStepApprovalArchiveList} from "../data/BuildStepApprovalArchiveList";
import {BuildStepApprovalWorkList} from "../data/BuildStepApprovalWorkList";
import BDDocumentsDetails from '../data/BrandDesignDocumentDetails';
import { Option,UpdateScanTable } from "src/containers/scanprofile-page/scanprofileutils";

const API_PREFIX = "/api/";

// TODO:
//  - Naming of file is off
//  - different concerns are mixed here (attachment, comment and approval (finding, buildstep))
const CIBuildStepOperation = {
  getInfo: (projectId: string): Promise<ProjectInfo> =>
    get(`${API_PREFIX}project/${projectId}/info`),

  uploadAttachment: (
    buildStepId: string,
    uploadFile: File,
    fileType: string
  ) => {
    const formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("type", uploadFile.type);
    return post(
      `${API_PREFIX}buildstep/${buildStepId}/attachment/type/${fileType}`,
      formData,
      "multipart/form-data"
    );
  },
  updateApplicationType:(applicationType:string)=>
  put(
    `${API_PREFIX}auth/updateapptype?applicationType=${applicationType}`
  ),
  downloadAttachment: (buildStepId: string, fileUUID: string) =>
    get(
      `${API_PREFIX}buildstep/${buildStepId}/attachment/${fileUUID}`,
      null,
      null,
      null,
      {
        responseType: "blob",
      }
    ),

  getAllBrandDesignImagesAsPdf: (buildStepId: string) =>
    getRaw(
      `${API_PREFIX}buildstep/${buildStepId}/pdf?timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      null,
      "application/octet-stream",
      null,
      { responseType: "blob" }
    ),

  getApprovalWorkList: (
    buildStepName: string
  ): Promise<BuildStepApprovalWorkList> =>
    get(`${API_PREFIX}buildstep/${buildStepName}/openapprovals`),

  getApprovalArchiveList: (
    buildStepName: string,
    page: number
  ): Promise<BuildStepApprovalArchiveList> =>
    get(`${API_PREFIX}buildstep/${buildStepName}/closedapprovals?page=${page}`),

  getApproval: (buildStepId: string): Promise<BuildStepApprovalDetail> =>
    get(`${API_PREFIX}buildstep/${buildStepId}/approval`),

  updateApproval: (approval: BuildStepApprovalDetail, buildStepId: string) =>
    put(`${API_PREFIX}buildstep/${buildStepId}/approval`, approval),

  cancelApproval: (buildStepId: string) =>
    put(
      `${API_PREFIX}buildstep/${buildStepId}/approvalstatus?appprovalStatus=NEW`,
      null,
      "text/plain"
    ),

  getBuildStepComments: (buildStepId: string) =>
    get(`${API_PREFIX}buildstep/${buildStepId}/comments`),

  postBuildStepComment: (buildStepId: string, comment: string) =>
    post(
      `${API_PREFIX}buildstep/${buildStepId}/comment`,
      comment,
      "text/plain"
    ),

  getFindingComments: (buildStepId: string, findingId: string) =>
    get(`${API_PREFIX}buildstep/${buildStepId}/finding/${findingId}/comments`),

  postFindingComment: (
    buildStepId: string,
    findingId: string,
    comment: string
  ) =>
    post(
      `${API_PREFIX}buildstep/${buildStepId}/finding/${findingId}/comment`,
      comment,
      "text/plain"
    ),

  getAttachmentComments: (buildStepId: string, attachmentId: string) =>
    get(
      `${API_PREFIX}buildstep/${buildStepId}/attachment/${attachmentId}/comments`
    ),
  postAttachmentComment: (
    buildStepId: string,
    attachmentId: string,
    comment: string
  ) =>
    post(
      `${API_PREFIX}buildstep/${buildStepId}/attachment/${attachmentId}/comment`,
      comment,
      "text/plain"
    ),
  getDocumentsDetails: (): Promise<BDDocumentsDetails> =>
    get(`${API_PREFIX}documents/allDocuments`),
  UploadDocumentDetails: (formData:any) => {
    return post(
      `${API_PREFIX}documents/uploadDocument`,
      formData,
      "multipart/form-data"
    );
  },
  downloadDocument: (url:string) =>
    get(
      `${API_PREFIX}documents/downloadDocument/${url}`,
      null,
      null,
      null,
      {
        responseType: "blob",
      }
    ),
  deleteDocument: (url:string) =>
    post(
      `${API_PREFIX}documents/deleteDocument/${url}`,
      null,
      null,
      null,
      {
        responseType: "blob",
      }
    ),
  editDocument: (formData:any) =>
    post(
      `${API_PREFIX}documents/editDocument`,
      formData,
      "form-data"
    ),
    deleteAttachment: (buildStepId: string, fileUUID: string) =>
    post(
      `${API_PREFIX}buildstep/${buildStepId}/attachment/deleteAttachment/${fileUUID}`,
      null,
      null,
      null,
      {
        responseType: "blob",
      }
    ),
    getApplicationType:() =>
    get(
      `${API_PREFIX}scanprofile/application`
    ),
    getReleaseType:() =>
    get(`${API_PREFIX}scanprofile/release`
    ),
    getDomains:() =>
    get(`${API_PREFIX}scanprofile/domains`
    ),
    getScanProfiles:() =>
    get(`${API_PREFIX}scanprofile/scans`
    ),
    getScanProfilesForReleaseTypeForProject:(applicationType:string ,releaseType: string,projectID:string) =>
    get(`${API_PREFIX}scanprofile/domains/${applicationType}/${releaseType}?projectID=${projectID}`
    ),
    getScanProfilesForReleaseType:(applicationType:string ,releaseType: string) =>
    get(`${API_PREFIX}scanprofile/domains/${applicationType}/${releaseType}`
    ),
    getDomainsForAppType:(applicationType:string) =>
    get(`${API_PREFIX}scanprofile/domains/${applicationType}`
    ),
    postScanProfile:(options:{applicationTypeDto:Option, releaseTypeDto:Option, domainDtoList: Option[]})=>post(
      `${API_PREFIX}scanprofile/addprofile`,
      options,
    ),
    updateScanTableList: (scanId: string,data: UpdateScanTable) =>
    put(
      `${API_PREFIX}scanprofile/updateprofile/${data.scanId}`,
      data
    ),

};

export default CIBuildStepOperation;