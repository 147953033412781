import { ActionTypes } from "../actions/flashMessage.actions";

const initialState = {
  isFlashMessageVisible: false,
  flashMessage: null,
  flashMessageType: null,
};

const flashMessage = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_MESSAGE:
      return {
        isFlashMessageVisible: true,
        flashMessage: action.payload.flashMessage,
        flashMessageType: action.payload.flashMessageType,
      };
    case ActionTypes.HIDE_MESSAGE:
      return {
        isFlashMessageVisible: false,
        flashMessage: null,
        flashMessageType: null,
      };
    default:
      return state;
  }
};

export default flashMessage;