import "@mercedes-benz/typeface-mb-corpo-a";
import "@mercedes-benz/typeface-mb-corpo-s";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { ThemeContextProvider } from "./context/ThemeContext";

import "bootstrap-css-only/css/bootstrap.min.css";
import "./i18n";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

import * as serviceWorker from "./serviceWorker";
import { ThemePage } from "./themePage";
import { localState, saveState } from "./utils/localStorage";

import { initialState } from './reducers/projects';

const middleware = [thunk];
if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger());
}

const persistedState = localState() ? localState() : {};
// create redux store and initialize user
export const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(...middleware)
);
store.subscribe(() => {
  saveState({
    auth: store.getState().auth,
    appInfo: store.getState().appInfo,
    flashMessageReducer: store.getState().flashMessageReducer,
    projects: {
      ...initialState,
      projectSearchFilter: store.getState().projects.projectSearchFilter,
    },
  });
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeContextProvider>
      <ThemePage />
    </ThemeContextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
export default store;
