import React, { createContext, useContext, useState, useRef } from "react";
import { CommentPopup } from "./CommentPopup";

const CommentPopupServiceContext = createContext(Promise.reject);

export const useCommentPopup = () => useContext(CommentPopupServiceContext);

export const CommentPopupServiceProvider = ({ children }) => {
  const [commentPopupState, setCommentPopupState] = useState(null);

  const awaitingPromiseRef = useRef();

  const openConfirmation = (options) => {
    setCommentPopupState(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (commentPopupState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setCommentPopupState(null);
  };

  return (
    <>
      <CommentPopupServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      <CommentPopup
        open={Boolean(commentPopupState)}
        onClose={handleClose}
        {...commentPopupState}
      />
    </>
  );
};
