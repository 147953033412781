export const ActionTypes = {
  SHOW_POPUP: "SHOW_POPUP",
  HIDE_POPUP: "HIDE_POPUP",
};

export const showLogoutPopup = () => ({
  type: ActionTypes.SHOW_POPUP,
});

export const hideLogoutPopup = () => ({
  type: ActionTypes.HIDE_POPUP,
});
