import { IconButton, Snackbar, SnackbarContent } from "@mui/material";
import { WbIcon } from "@workbench/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideFlashMessage } from "../../actions/flashMessage.actions";
import { colors } from "../../utils/colors";
import {
  closeMessageStyle, MessageContainer,
  MessageParagraph,
  SnackbarAnchor,
  SnackbarContentProps,
  SnackbarContentStyle
} from "./FlashMessage.style";

export const FlashMessage = () => {
  const dispatch = useDispatch();
  const { isFlashMessageVisible, flashMessage, flashMessageType } = useSelector(
    (state) => state.flashMessageReducer
  );
  const hide = () => dispatch(hideFlashMessage());
  return (
    <Snackbar
      anchorOrigin={SnackbarAnchor}
      ContentProps={SnackbarContentProps}
      open={isFlashMessageVisible}
      onClose={hide}
    >
      <SnackbarContent
        style={SnackbarContentStyle(flashMessageType, colors)}
        message={
          <MessageContainer>
            <MessageParagraph>{flashMessage}</MessageParagraph>
            <IconButton onClick={hide} style={{ height: 39 }} size="large">
              <WbIcon name="bds/close/24" style={closeMessageStyle} />
            </IconButton>
          </MessageContainer>
        }
      />
    </Snackbar>
  );
};
