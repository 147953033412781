import { get, getRaw, post } from "./api";
import {
  UserAckConfirmRequest,
  UserAcknowledgement, UserAcknowledgementCommandResult,
} from "../data/UserAcknowledgement";

const API_PREFIX = "/api/user/acknowledgement";

export const useracknowledgementApi = {
  getAcknowledgements: (): Promise<UserAcknowledgement[]> =>
    get(`${API_PREFIX}`),
  checkUserAcknowledgements: (): Promise<UserAcknowledgementCommandResult> =>
    post(`${API_PREFIX}/check`),
  confirmAcknowledgements: (
    confirmRequest: UserAckConfirmRequest
  ): Promise<UserAcknowledgementCommandResult> => post(`${API_PREFIX}/confirm`, confirmRequest),
};
