import { BuildActionTypes } from "../actions/CIBuildStep.action";

const initialState = {
  error: null,
  appInfo: null,
  imageInfos: [],
  fileInfos: null,
};

export default function CIBuildStep(state = initialState, action) {
  switch (action.type) {
    case BuildActionTypes.FETCH_APP_INFO_REQUEST:
      return {
        initialState,
      };

    case BuildActionTypes.FETCH_APP_INFO_SUCCESS: {
      return {
        ...state,
        appInfo: action.appInfo,
      };
    }

    case BuildActionTypes.FETCH_APP_INFO_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case BuildActionTypes.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        error: null,
      };

    case BuildActionTypes.UPLOAD_FILE_SUCCESS: {
      const { approvalDTO } = state;
      if (approvalDTO.attachments == null || approvalDTO.attachments == null) {
        approvalDTO.attachments = [];
      }

      if (
        action.fileInfo.attachmentType == "FILE" ||
        approvalDTO.attachments.length == 0
      ) {
        approvalDTO.attachments.push(action.fileInfo);
      } else if (action.fileInfo.attachmentType.includes("BRAND_DESIGN")) {
        const foundIndex = approvalDTO.attachments.findIndex(
          (x) => x.attachmentType == action.fileInfo.attachmentType
        );
        if (foundIndex >= 0) {
          approvalDTO.attachments[foundIndex] = action.fileInfo;
        } else {
          approvalDTO.attachments.push(action.fileInfo);
        }
      }
      return {
        ...state,
        approvalDTO,
      };
    }

    case BuildActionTypes.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case BuildActionTypes.GET_APPROVAL_REQUEST:
      return {
        ...state,
        error: null,
      };

    case BuildActionTypes.GET_APPROVAL_SUCCESS: {
      return {
        ...state,
        approvalDTO: action.approvalDTO,
      };
    }

    case BuildActionTypes.GET_APPROVAL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
