import { DarkTheme, grey, LightTheme, yellow } from '@mercedes-benz/mui5-theme';
import { createTheme } from '@mui/material';
import { Theme } from '@mui/system';
import { deepmerge } from '@mui/utils';
import * as React from 'react';
import { useMemo } from 'react';

export const ThemeContext = React.createContext<ThemeContextType | null>(null);

export type SelectableThemes = 'MaterialUI' | 'MBThemeLight' | 'MBThemeDark';

type ThemeContextType = {
  theme: Theme;
  themeType: SelectableThemes;
  switchTheme: (themeId: SelectableThemes) => void;
};

const masspTheme = deepmerge(LightTheme, {
  palette: {
    background: {
      default: grey[85]
    },
    warning: {
      main: yellow[50],
  },
  }
}, {clone: true});

export const ThemeContextProvider: React.FC<unknown> = ({ children }) => {
  const [theme, setTheme] = React.useState<Theme>(masspTheme);
  const [themeType, setThemeType] = React.useState<SelectableThemes>('MBThemeLight');

  const switchTheme = (themeID: SelectableThemes) => {
    setThemeType(themeID);
  };

  React.useEffect(() => {
    switch (themeType) {
      case 'MaterialUI':
        setTheme(createTheme());
        break;
      case 'MBThemeLight':
        setTheme(masspTheme);
        break;
      case 'MBThemeDark':
        setTheme(DarkTheme);
        break;
    }
  }, [themeType, setTheme]);

  const values = useMemo<ThemeContextType>(
    () => ({
      theme,
      themeType,
      switchTheme,
    }),
    [theme, themeType],
  );

  return <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>;
};

export const useTheme = (): ThemeContextType => {
  const context = React.useContext(ThemeContext);

  if (context == null) {
    throw new Error('useTheme hook must be used with a ThemeContextProvider component');
  }

  return context;
};