import { ActionTypes } from "../actions/projects";

export const initialState = {
  isFetching: true,
  isChecking: false,
  isCanceling: false,
  hasError: false,
  error: null,
  pickedRole: null,
  projects: [],
  projectSearchFilter: "",
};

export default function projects(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.INIT_PROJECTS_STATE:
      return {
        isFetching: false,
        isChecking: false,
        isCanceling: false,
        hasError: false,
        error: null,
        projects: [],
       projectSearchFilter:action.payload
      };
    case ActionTypes.FETCH_PROJECTS_REQUEST:
      return {
        ...state,
        hasError: false,
        error: null,
      };
    case ActionTypes.FETCH_PROJECTS_REQUEST_BACKGROUND:
      return {
        ...state,
        hasError: false,
        error: null,
      };
    case ActionTypes.FETCH_FILTERED_PROJECTS_REQUEST:
      return {
        ...state,
        hasError: false,
        error: null,
      };

    case ActionTypes.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        projects: action.projects,
      };

    case ActionTypes.FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypes.SET_ERROR_WITH_MESSAGE:
      return {
        ...state,
        error: action.message,
        hasError: true,
      };

    case ActionTypes.CHECK_PROJECT_REQUEST:
      return {
        ...state,
        isChecking: true,
      };

    case ActionTypes.CANCEL_PROJECT_REQUEST:
      return {
        ...state,
        isCanceling: true,
      };

    case ActionTypes.FETCH_DATA:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypes.CHECK_PROJECT_SUCCESS: {
      return {
        ...state,
        isChecking: false,
      };
    }

    case ActionTypes.CANCEL_PROJECT_SUCCESS: {
      return {
        ...state,
        isCanceling: false,
      };
    }

    case ActionTypes.CHECK_PROJECT_FAILURE:
      return {
        ...state,
        isChecking: false,
      };

    case ActionTypes.CANCEL_PROJECT_FAILURE:
      return {
        ...state,
        isCanceling: false,
      };

    case ActionTypes.SET_PROJECT_SEARCH_FILTER:
      return {
        ...state,
        projectSearchFilter: action.filterTerm,
      };

    default:
      return state;
  }
}
