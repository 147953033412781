import { get, getRaw, post, put, remove } from "./api";

const AUTH_PREFIX = "/auth";
const API_PREFIX = "/api/";

const massp = {
  add: (data) => post(`${API_PREFIX}project`, data),
  check: (projectId) => put(`${API_PREFIX}project/${projectId}/check`),
  cancel: (projectId) => remove(`${API_PREFIX}build/${projectId}`),
  delete: (projectId) => remove(`${API_PREFIX}project/${projectId}`),
  getAll: (applicationType) => get(`${API_PREFIX}project?applicationType=${applicationType}`),
  getFilteredProjects: (query, applicationType) => get(`${API_PREFIX}project?query=${query}&applicationType=${applicationType}`),
  update: (data, projectId) => put(`${API_PREFIX}project/${projectId}`, data),
  createVersion: (projectId, data) =>
    post(`${API_PREFIX}project/${projectId}/version`, data),
  getProject: (projectId) => get(`${API_PREFIX}project/${projectId}`),
  getProjectsRaport: () =>
    get(`${API_PREFIX}export/projects/excel`, null, null, null, {
      responseType: "blob",
    }),
  getProjectsSechubReport: () =>
    get(`${API_PREFIX}export/projects/sechub`, null, null, null, {
      responseType: "blob",
    }),
  getProjectsBillingReport: () =>
    get(`${API_PREFIX}export/projects/billing`, null, null, null, {
      responseType: "blob",
    }),
  getProjectBuilds: (projectId) =>
    get(`${API_PREFIX}project/${projectId}/build`),
  getAuthConfig: () => get(`${AUTH_PREFIX + API_PREFIX}iam/config`),
  getConfiguration: () => get(`${API_PREFIX}configuration`),
  getVersionInfo: () => get(`${API_PREFIX}external/versioninfo`),
  updateAppSupportLink: (projectId, appSupportLink) =>
    put(
      `${API_PREFIX}project/${projectId}/supportlink`,
      appSupportLink,
      "text/plain"
    ),
  download: (path) => getRaw(path, null, null, null, { responseType: "blob" }),
  getProjectUserChecks: (projectId) =>
    get(`${API_PREFIX}admin/userchecks/projects/${projectId}`),

  getPlanningItAppInfo: (planningItAppId) =>
    get(`${API_PREFIX}planningit/${planningItAppId}`),
  logSupportLinkUsage: () => {
    post(`${API_PREFIX}logging/usage/supportlink`);
  },
};

export default massp;