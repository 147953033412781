import massp from "../api/massp";
import { saveAs } from "../utils/FileUtil";

export const ActionTypes = {
  OPEN_DETAILS_DIALOG: "OPEN_DETAILS_DIALOG",
  CLOSE_DETAILS_DIALOG: "CLOSE_DETAILS_DIALOG",
  DOWNLOAD_PDF_REQUEST: "DOWNLOAD_PDF_REQUEST",
  DOWNLOAD_PDF_FAILURE: "DOWNLOAD_PDF_FAILURE",
  DOWNLOAD_PDF_SUCCESS: "DOWNLOAD_PDF_SUCCESS",
};

export const openDetailsDialog = (buildStep) => {
  return {
    type: ActionTypes.OPEN_DETAILS_DIALOG,
    buildStep: {
      ...buildStep,
    },
  };
};

export const closeDetailsDialog = () => ({
  type: ActionTypes.CLOSE_DETAILS_DIALOG,
});

const downloadPdfRequest = () => ({
  type: ActionTypes.DOWNLOAD_PDF_REQUEST,
});

const downloadPdfSuccess = () => ({
  type: ActionTypes.DOWNLOAD_PDF_SUCCESS,
});

const downloadPdfFailure = (error) => ({
  type: ActionTypes.DOWNLOAD_PDF_FAILURE,
  error,
});

export const downloadPDF = (path) => (dispatch) => {
  dispatch(downloadPdfRequest());

  massp.download(path).then(
    (pdf) => {
      saveAs(path.replace(/^.*\//, ""), pdf);
      dispatch(downloadPdfSuccess());
    },
    (error) => {
      dispatch(downloadPdfFailure(error));
    }
  );
};
