import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import { useTheme } from "./context/ThemeContext";
import App from "./containers/App/App";

export const ThemePage: React.FC<unknown> = () => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};
